import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box } from '@mui/system';

function getNavigatorLanguage () {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
}

function createData(name, datetime, tz, id, dateid) {
    const dt = new Date(datetime);
    const locale = getNavigatorLanguage();

    console.log("dt", dt, datetime);
    const date = dt.toLocaleDateString( locale , { timeZone: tz  });
    const time = dt.toLocaleTimeString( locale ,  { timeZone: tz, hour: 'numeric', minute: "numeric" });
    return { name,  date, time, id, dateid };
}
const rows = window?.events?.map( e => {
    return createData(e.name, e.datetime, e.tz, e.id, e.dateid);
}) || [];

export default function ClassList( props ) {
    const {openPanel} = props;
    return (
        <Card variant="outlined">
            <CardContent>
                <TableContainer>
                    <Table aria-label="Class lists">
                        <TableHead>
                        <TableRow>
                            <TableCell>Class</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell sx={{display: { xs: 'none', sm: 'table-cell' } }} align="left">Time</TableCell>
                            <TableCell sx={{display: { xs: 'none', sm: 'table-cell' } }} align="right">Manager&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            rows?.length === 0 && (
                                <React.Fragment>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, display: { xs: 'none', sm: 'block' } }}
                                    >
                                    <TableCell colSpan={2}>
                                        <Box sx={{p: 10}}>
                                            <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You have no classes assigned. Please contact your account administrator.</Typography>
                                        </Box>
                                    </TableCell>
                
                                </TableRow>    
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, display: { xs: 'block', sm: 'none' } }}
                                    >
                                    <TableCell colSpan={4}>
                                        <Box sx={{p: 10}}>
                                            <Typography variant="h6" component="div" style={{textAlign: 'center'}}>You have no classes assigned. Please contact your account administrator.</Typography>
                                        </Box>
                                    </TableCell>
                
                                </TableRow>    

                                </React.Fragment>
 
                            )
                        }
                        {rows.map((row) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row"  sx={{display: { xs: 'none', sm: 'table-cell' } }}>
                                {row.name}
                            </TableCell>
                            <TableCell component="th" scope="row"  sx={{display: { xs: 'table-cell', sm: 'none' } }}>
                                <Link onClick={( e ) => openPanel( row.id, row.dateid )}>{row.name}</Link> 
                            </TableCell>                            
                            <TableCell align="left">
                                {row.date} <Box sx={{display: { xs: 'inline', sm: 'none' } }}>- {row.time}</Box>
                            </TableCell>
                            <TableCell sx={{display: { xs: 'none', sm: 'table-cell' } }} align="left">
                                {row.time}
                            </TableCell>
                            <TableCell sx={{display: { xs: 'none', sm: 'table-cell' } }} align="right">
                            <Button variant="outlined" onClick={( e ) => openPanel( row.id, row.dateid )} startIcon={<ManageAccountsIcon />}>
                                Open
                            </Button>
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>


    );
}