import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SendIcon from '@mui/icons-material/Send';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { post } from '../libraries/network';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://reservie.net/">
        reservie Ltd
      </Link>{' '}
            {new Date('2015-01-01 00:00:00').getFullYear()} - {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Password() {

  const [showpassword, setShowPassword] = React.useState( false )
  const [confirmshowpassword, setConfirmShowPassword] = React.useState( false )
  const [passmismatch, setPassMissmatch] = React.useState( false )
  const [password, setPassword] = React.useState( '' )
  const [cpass, setCPass] = React.useState( '' )
  const [passworderror, setPasswordError] = React.useState( false );
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState(false);


  const onSetPassword = ( password ) => {
    var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/;
    var isValidPassword = passwordRegex.test(password);
    setPassword( password );
    setPasswordError( !isValidPassword ); 
    checkMatch( password, cpass )
    console.log('Password Validation',password,isValidPassword );
  }

  const handleClickShowPassword = () => {
    setShowPassword( !showpassword );
  }

  const handleMouseDownPassword = () => {
    setShowPassword( !showpassword );
  }

  const handleClickConfirmShowPassword = () => {
    setConfirmShowPassword( !confirmshowpassword );
  }

  const handleMouseConfirmDownPassword = () => {
    setConfirmShowPassword( !confirmshowpassword );
  }

  const checkMatch = ( a, b ) => {
    console.log("Pass checks", a.length, b.length, a, b)
    if( a.length > 0 && b.length > 0 )
    {
      if( a !==  b )
      {
        setPassMissmatch(true);
        return;
      }
    }
    setPassMissmatch(false);
  }


  const handleSubmit = async(event) => {

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      password: data.get('password'),
      confirmation: data.get('confirm-password')
    });

    if(passmismatch)
    {
        console.error("Password mistmatch", password, cpass);
        return;
    }

    const s = `password=${encodeURIComponent(data.get('password'))}&confirmation=${encodeURIComponent(data.get('confirm-password'))}`;

    try{

      setBusy(true)
      const d = await post( `/facilitators/ajax-reset-password`, s);

      if(d.status === 'OK')
      {
        setTimeout(async () => {

          const {
            origin
          } = window.location;
          window.location.replace(`${origin}/facilitators/manage`);
          setBusy(false)
  
        }, 500);
      }
      else
      {
        console.error(d);
        setError('Password update failed. Please check the details and try again.');
        setTimeout(async () => {
          setBusy(false)
        }, 1000);

      }


    }
    catch(e)
    {
        if(window?.static_override)
        {
            setTimeout(async () => {

            const {
              origin
            } = window.location;
            window.location.replace(`${origin}/facilitators/manage`);
            setBusy(false)

          }, 2000);

        }
        else
        {
            console.error(e);
            setError("Sorry, we have detected a network error. Please try again later.");
            setTimeout(async () => {
              setBusy(false)
            }, 1000);            
        }

    } 

  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Facilitators forgotten password reset
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth required  >
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                type={showpassword ? 'text' : 'password'}
                required
                autoComplete="current-password"
                value={password}
                inputProps={{pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,128}$" }}
                onChange={(e) => {onSetPassword(e.target.value)}}
                error={passworderror}
                disabled={busy}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showpassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <FormHelperText >{`Passwords must be at least 8 characters long. Use one or more of each of the following: uppercase characters, lowercase characters, digits (0-9) and special characters (such as -, !, ?, $, &). Passwords must be at least 8 characters long.`}</FormHelperText>
            </FormControl>


            <FormControl sx={{ mt: 3 }} variant="outlined" fullWidth required>
              <InputLabel htmlFor="confirm-outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="confirm-outlined-adornment-password"
                name="confirm-password"
                type={confirmshowpassword ? 'text' : 'password'}
                required
                autoComplete="current-password"
                value={cpass}
                disabled={busy}
                onChange={(e) => {setCPass(e.target.value); checkMatch( password, e.target.value)}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmShowPassword}
                      onMouseDown={handleMouseConfirmDownPassword}
                      edge="end"
                    >
                      {confirmshowpassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            <Grid container  justifyContent='flex-start' sx={{mt: 2}}>
              <Grid item sm> 
                {
                  passmismatch &&
                  <Alert severity="warning">Passwords do not match</Alert>
                }       
              </Grid>
            </Grid>
            {
              !!error &&
              <Box sx={{pt: 2, pb: 2}}>
                <Alert severity="error">{error}</Alert>
              </Box>
            }            

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SendIcon />}
              disabled={busy || passmismatch || password.length < 8 || cpass.length < 8}
            >
              Save and sign-in
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}