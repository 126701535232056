import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Stack } from '@mui/system';
import { Alert, Backdrop, CircularProgress } from '@mui/material';

export default function ClassNote( props ) {
  const {open,  classdetail, onClose, onBlock } = props;

  const [note, setNote] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const onSave = ( event ) => {
    event.preventDefault();
    const data = '&note=' + encodeURI( note ); 
    console.log("onSave - posting note");
    setLoading(true);
    onBlock(true);

    // customerid/eid/cid/
    fetch(`/facilitators/add_class_note_json/${classdetail.eid}/${window?.configuration.cid}`,{
        method: "POST",
        headers : { 
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": 'XMLHttpRequest',
            "X_REQUESTED_WITH": 'xmlhttprequest',
            'Accept': 'application/json',
            'credentials': "include",
         },
         body: window.configuration.tokenname + '=' + window.configuration.tokenvalue + data
      })
      .then(function(response){
          console.log(response)
          return response.json();
      })
      .then(function(json) {
          console.log(json);
          setLoading(false);
          setError(false);

        }).catch((err) => {
        console.log(err);
        setError('Failed to save note. Please try again.')
        setLoading(false);
        onBlock (false)
      });      

  }


  return (
    <div>
        <Dialog open={open} onClose={onClose}>
            <Box component="form" onSubmit={onSave}>
            <DialogTitle>Notes</DialogTitle>
            <DialogContent>
                <DialogContentText>
                Any notes that you add to this class will be sent to the account owner.
                </DialogContentText>

                <TextField
                    autoFocus
                    margin="dense"
                    id="note"
                    label="Note"
                    type="text"
                    name="note"
                    required
                    multiline={true}
                    fullWidth
                    variant="standard"
                    value={note}
                    onChange={(e) => {setNote(e.target.value)}}
                />
                {
                    error &&
                    <Alert severity="error">{error}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" disabled={note.length === 0 || loading}>Save</Button>
            </DialogActions>
            </Box>
            {
                loading && 
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={!!loading}
                >
                <Box>
                    <Stack>
                        <CircularProgress size="8em" color="inherit" />
                        <Box sx={{mt:2}}>Please wait...</Box>
                    </Stack>
                </Box>
                </Backdrop>
            }
        </Dialog>
    </div>
  );
}