import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Backdrop, Box, CircularProgress, FormControlLabel, IconButton, Link, Switch, Typography } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import StarRateIcon from '@mui/icons-material/StarRate';
import Notes from './Notes';
import AttendeeDetail from './AttendeeDetail';
import { post } from '../libraries/network';


export default function AttendeeList( props ) {
    const {classdetail, onBlock, rows} = props;
    const [clientrows, setClientRows] = React.useState(rows);
    const [notes, setNotes] = React.useState(false);
    const [side, setSide]   = React.useState( false );
    const [error, setError] = React.useState( false );
    const [busy, setBusy] = React.useState( false );
    const handleAttenedToggle = async ( state, id, instance, purchaseid, dateid) => {
        // to do - post ajax
        console.log("Toggle!", clientrows);

        try{
    
          setBusy( true );
          const s = `id=${encodeURI(id)}&instance=${encodeURI(instance)}&purchaseid=${encodeURI(purchaseid)}&eid=${encodeURI(classdetail.eid)}&dateid=${encodeURI(classdetail.dateid)}&state=${state}`;
          const d = await post( `/facilitators/ajax-mark-attended`, s);
    
          if(d.status === 'OK')
          {
                setClientRows( rows.map( r => {
                    console.log("comparing", r.id, id)
                    if(r.id === id && r.instance === instance && r.purchaseid === purchaseid && r.dateid === dateid) 
                    {
                        r.attended = state;
                    }
                    return r;
                }));
                setBusy( false );

          }
          else
          {
            setBusy( false );
            setError('Failed to update records');
          }
        }
        catch(e)
        {
            if(window?.static_override)
            {
                setTimeout(async () => {
    
                    setBusy( false );
                    setClientRows( rows.map( r => {
                        console.log("comparing", r.id, id)
                        if(r.id === id)
                        {
                            r.attended = state;
                        }
                        return r;
                    }));    
              }, 1000);
    
            }
            else
            {
                setBusy( false );
                setError("Sorry, we have detected a network error. Please try again later.");
            }
    
        }
        
    }
    return (

        <React.Fragment>
            <TableContainer component={Paper} variant="outlined">
            <Table aria-label="Class register">
                <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left" >Spaces</TableCell>
                    <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'block' }}} >Pricing option</TableCell>
                    <TableCell align="left">Attended</TableCell>
                    <TableCell align="right">Note</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    rows.length === 0 && 
                    <TableRow component="th" scope="row">
                        <TableCell colSpan={4}>
                            <Box sx={{p: 10}}>
                                <Typography variant="h6" component="div" style={{textAlign: 'center'}}>There are no clients booked on this class. Please contact your account administrator if you have any questions.</Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                }
                {rows.map((row) => (
                    <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {
                            (window.configuration.allow_view_questionnaire === true ||  window.configuration.allow_view_notes === true) &&
                            <span><Link onClick={(e) => { setSide( row ) }}>{`${row.firstname} ${row.lastname}`} </Link> {row?.important && <StarRateIcon fontSize='small'/>}</span>
                        }
                        {
                            (window.configuration.allow_view_questionnaire !== true &&  window.configuration.allow_view_notes !== true) &&
                            <span>{`${row.firstname} ${row.lastname}`} {row?.important && <StarRateIcon fontSize='small'/>}</span>
                        }
                        
                    </TableCell>
                    <TableCell align="left">{row.spaces}</TableCell>
                    <TableCell align="left"  sx={{ display: { xs: 'none', sm: 'table-cell' }}} >{row.option}</TableCell>
                    <TableCell aligh="left">
                        <FormControlLabel
                        control={
                            <Switch checked={row.attended} onChange={(e) => handleAttenedToggle( e.target.checked, row.id, row.instance, row.purchaseid, row.dateid)} name="jason" />
                        }
                        />
                                        
                    </TableCell>
                    <TableCell  align="right">
                    <IconButton color="primary" disabled={!window?.configuration?.allow_add_client_notes} onClick={(e) => {setNotes(row.id)}} onBlock={onBlock}><NoteAddIcon/></IconButton>
                    </TableCell>
        
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            {
                notes !== false && 
                <Notes onClose={(e) =>{ setNotes( false )}} classdetail={classdetail} onBlock={onBlock} id={notes} open={true} /> 
            }
            {
                side !== false && 
                <AttendeeDetail open={ !!side } attendee={ side } setClose={(e) => { setSide( false )}}/>
            }
            {
                busy &&
                <Backdrop  open={busy} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} >
                    <CircularProgress color="inherit" />
                </Backdrop>

            }
        </React.Fragment>

    );
}