import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { post } from '../libraries/network';
import { Alert } from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://reservie.net/">
        reservie Ltd
      </Link>{' '}
            {new Date('2015-01-01 00:00:00').getFullYear()} - {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Reset() {
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSubmit = async(event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
    });

    const s = `email=${encodeURIComponent(data.get('email'))}`;

    try{

      setBusy(true)
      const d = await post( `/facilitators/ajax-request-code`, s);

      if(d.status === 'OK')
      {
        setTimeout(async () => {

          const {
            origin
          } = window.location;
          window.location.replace(`${origin}/facilitators/code`);
          setBusy(false)
  
        }, 500);
      }
      else
      {
        setError('Reset request has failed. Please check the details and try again.');
        console.error(d);
        setTimeout(async () => {
          setBusy(false)
        }, 1000);   
      }


    }
    catch(e)
    {
        if(window?.static_override)
        {
            setTimeout(async () => {

            const {
              origin
            } = window.location;
            window.location.replace(`${origin}/facilitators/code`);
            setBusy(false)

          }, 2000);

        }
        else
        {
            console.error(e);
            setError("Sorry, we have detected a network error. Please try again later.");
            setTimeout(async () => {
              setBusy(false)
            }, 1000);   
         }

    }      
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          Facilitators forgotten password reset
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              disabled={busy}
              autoFocus
            />
            <Box sx={{pt:2, pb:2}}>
            {
                error &&
                <Alert severity="error">{error}</Alert>

              }
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SendIcon />}
              disabled={busy}
            >
              Continue
            </Button>
            <Grid container>
              <Grid item sm>
                <Link href={`/facilitators`} 
                      variant="body2"
                      disabled={busy}
                >
                  Know your sign-in details? Click here to sign-in
                </Link>
              </Grid>
            </Grid>          
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}