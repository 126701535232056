import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Backdrop, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack } from '@mui/material';
import { Box, Container } from '@mui/system';
import ClassList from '../elements/ClassList';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttendeeList from '../elements/AttendeeList';
import ClassSummary from '../elements/ClassSummary';
import RegisterClientDialog from '../elements/RegisterClient';
import ClassNote from '../elements/ClassNote';
import ResponsiveAppBar from '../elements/appBar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Manage() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState('...');
  const [classClientList, setClassClientList] = React.useState([]);
  const [fullClientList, setFullClientList] = React.useState([]);
  const [classdetail, setClassDetail] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [registerClient, setRegisterClient] = React.useState(false);
  const [registerClassNote, setRegisterClassNote] = React.useState(false);
  
  const [busy, setBusy] = React.useState(false);

  const handleClickOpen = ( id, dateid ) => {
    setOpen(`${id}/${dateid}`);
    setLoading( true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  }



  const handleMenuRegisterClient = () => {
    setAnchorEl(null);
    setRegisterClient(true);
    console.log("Registration", anchorEl, registerClient);
  }


  const handleMenuRegisterClassNote = () => {
    setAnchorEl(null);
    setRegisterClassNote(true);
    console.log("Registration class note", anchorEl, registerClient);
  }


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    if(!!open)
    {
      fetch(`/facilitators/get-register-json/${open}`,{
        headers : { 
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Requested-With": 'XMLHttpRequest',
          "X_REQUESTED_WITH": 'xmlhttprequest',
          'Accept': 'application/json',
          'credentials': "include"
         }
      })
      .then(function(response){
          console.log(response)
          return response.json();
      })
      .then(function(json) {
          console.log(json);
          setClassClientList(json.data);
          setClassDetail(json.settings);
          setLoadingText(json?.settings?.name || '...');
          setFullClientList(json.customers);
          setLoading(false);
      }).catch((err) => {
        console.log(err);
        if(window?.use_test_data)
        {
          setLoading(false);
          setLoadingText(window?.static_class_setting?.name);
          setClassClientList(window.static_class_client_list);
          setClassDetail(window.static_class_setting);
          setFullClientList(window?.static_full_client_list);
        }
        else
        {
          setError("We have failed to load the selected register. Please check your network connection and try again.");
          setOpen(false);
          setLoading(false);
          setClassClientList([]);
          setFullClientList([]);
          setClassDetail(null);
        }
      });      
    }
  }, [open, registerClient])


  console.log("RegistrationClient state", registerClient);
  return (
    <div className='rvNS'>
      <Container component="main">
        <Box sx={{mt:2}}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <ResponsiveAppBar  title={"Assigned classes"} />
              </Grid>
              <Grid item xs={12}>
                <ClassList openPanel={handleClickOpen}/>
              </Grid>
          </Grid>
        </Box>
      </Container>


        <Dialog
          fullScreen
          open={!!open}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{backgroundColor: '#ebeff3'}}
          className="dialog"
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {loadingText}
              </Typography>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuRegisterClient} disabled={!window?.configuration?.allow_client_registration}>Client registration</MenuItem>
                <MenuItem onClick={handleMenuRegisterClassNote} disabled={!window?.configuration?.allow_add_class_note}>Add class note</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
          <Box sx={{p: 2, backgroundColor: '#ebeff3'}}>
              <Grid container>
                <Grid item xs={12}>
                  <ClassSummary classdetail={classdetail} />
                </Grid>
                <Grid item xs={12}>
                  <AttendeeList classdetail={classdetail} onBlock={(e) => setBusy(e)} rows={classClientList} />
                </Grid>
              </Grid>
          </Box>

          {
            busy &&
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }

          {
            loading && 
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={!!open}
            >
              <Box>
                <Stack>
                    <CircularProgress size="8em" color="inherit" />
                    <Box sx={{mt:2}}>Fetching register</Box>
                </Stack>
              </Box>
            </Backdrop>
          }
        </Dialog>

        {
          registerClient && <RegisterClientDialog onBlock={(e) => setBusy(e)} open={true} settings={classdetail} setClose={(e) => setRegisterClient(false)} clients={fullClientList}/>
        }

        {
          registerClassNote && <ClassNote open={true} onClose={(e) => setRegisterClassNote(false) }classdetail={classdetail} onBlock={(e) => setBusy(e)} />
        }

        {
          !!error && 
          <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="title-danger">
            {"Error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => setError(false)} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        }
    </div>
  );
}