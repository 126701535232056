import * as React from 'react';
import {  Card, CardContent, Grid, Typography } from '@mui/material';

import { Box } from '@mui/system';

function getNavigatorLanguage () {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
}


export default function ClassSummary( props ) {
    const {openPanel, classdetail} = props;

    return (
        <Box sx={{pt: 2, pb: 2}}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {classdetail?.name}
                            </Typography>
                            <Typography variant="h8" component="div">
                                {`${( new Date( classdetail?.datetime)).toLocaleDateString( getNavigatorLanguage(), {timeZone: classdetail?.tz})} ${( new Date( classdetail?.datetime)).toLocaleString( getNavigatorLanguage(), {hour: '2-digit', minute:'2-digit', timeZone: classdetail?.tz} )} ${Intl.DateTimeFormat().resolvedOptions().timeZone} time`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{mt:3}}>
                                This class has a capacity of {classdetail?.capacity} spaces and {classdetail?.used} people booked.
                            </Typography>                        
                        </CardContent>                        
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Access details
                            </Typography>
                            <Typography variant="h8" component="div">

                                Type of class: {classdetail?.type === 'PHYSICAL' ? 'In-studio' : 'Online'}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{mt:3}}>
                                Access:&nbsp;
                                {
                                    classdetail?.type === 'PHYSICAL' &&
                                    <span>N/A</span>
                                }
                                {
                                    classdetail?.type === 'VIRTUAL' &&
                                    <span> <a rel="noreferrer" href={`${classdetail.access}`} target="_blank">{`${classdetail.access}`}</a></span>
                                }
                            </Typography>                        
                        </CardContent>                        
                    </Card>
                </Grid>
            </Grid>

        </Box>
    );
}