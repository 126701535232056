import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import QuestionnaireDialog from './Questionnaire';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function NotesAccordian( props ) {
  const {notes} = props;
  const [expanded, setExpanded] = React.useState(false);
  const tz = 'Etc/UCT';

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
}

  return (
    <div>
      {
        notes?.map( (n, i) => (
          <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${i}bh-content`}
              id={`panel${i}bh-header`}
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                {`${(new Date(n?.created)).toLocaleDateString()} ${(new Date(n.created)).toLocaleTimeString( getNavigatorLanguage() ,  { timeZone: tz, hour: 'numeric', minute: "numeric" })}`}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{n?.creator}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {n?.note}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  );
}




function SectionTabs( props ) {
    const {questionnaires, notes} = props;
    const [value, setValue] = React.useState(window.configuration.allow_view_questionnaire ? '1' : '2');
    const [questionnaire, setQuestionnaire] = React.useState(false);
  
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ width: '100%', typography: 'body2' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider',  m: 4 }}>
            <TabList onChange={handleChange} aria-label="Customor information">
              {
                window.configuration.allow_view_questionnaire &&
                <Tab label="Questionnaires" value="1" />
              }
              {
                window.configuration.allow_view_notes &&
                <Tab label="Notes" value="2" />
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <TableContainer component={Paper} variant="outlined">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Expires</TableCell>
                            <TableCell align="right">View&nbsp;&nbsp;&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            questionnaires?.map(( q ) => (
                                <TableRow key={'questionnaire-' + q.id}>
                                    <TableCell>{q.id}</TableCell>
                                    <TableCell>{(new Date(q.created)).toLocaleDateString()}</TableCell>
                                    <TableCell>{(new Date(q.expires)).toLocaleDateString()}</TableCell>
                                    <TableCell align="right"><IconButton onClick={(e)=> { setQuestionnaire( q )}} disabled={!window?.configuration?.allow_view_questionnaire}><SupervisedUserCircleIcon fontSize='large' className='act' /></IconButton></TableCell>
                                </TableRow>
                            ))
                        }

                    </TableBody>
                </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value="2">
              <NotesAccordian notes={notes}/>
          </TabPanel>
        </TabContext>
        {
            questionnaire !== false &&
            <QuestionnaireDialog open={!!questionnaire} questionnaire={questionnaire} setClose={(e) => setQuestionnaire(false)}/>

        }
      </Box>
    );
  }


export default function AttendeeDetail( props ) {

  const {open, attendee, setClose} = props;

  const handleClose = () => {
    setClose(false);
  };

  return (
    <div>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {`${attendee?.firstname} ${attendee?.lastname}`}
            </Typography>

          </Toolbar>
        </AppBar>
        <SectionTabs questionnaires={attendee?.questionnaires} notes={attendee?.notes}/>
      </Dialog>
    </div>
  );
}