import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { post } from '../libraries/network';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://reservie.net/">
        reservie Ltd
      </Link>{' '}
            {new Date('2015-01-01 00:00:00').getFullYear()} - {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {

  const [showpassword, setShowPassword] = React.useState( false )
  const [email, setEmail] = React.useState('');
  const [busy, setBusy] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(false);
  const [emailerror, setEmailError] = React.useState(false);
  const [passworderror, setPasswordError] = React.useState( false );
  const handleSubmit =  async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    const s = `email=${encodeURIComponent(data.get('email'))}&password=${encodeURIComponent(data.get('password'))}`;

    try{

      setBusy(true)
      const d = await post( `/facilitators/ajax-login`, s);

      if(d.status === 'OK')
      {
        setTimeout(async () => {

          const {
            origin
          } = window.location;
          window.location.replace(`${origin}/facilitators/manage`);
          setBusy(false)
  
        }, 500);
      }
      else
      {
        setError('Invalid username or password. Please check the details and try again.');
        console.error(d);
        setTimeout(async () => {
          setBusy(false)
        }, 1000);           
      }


    }
    catch(e)
    {
        if(window?.static_override)
        {
            setTimeout(async () => {

            const {
              origin
            } = window.location;
            window.location.replace(`${origin}/facilitators/manage`);
            setBusy(false)

          }, 2000);

        }
        else
        {
            setError("Sorry, we have detected a network error. Please try again later.");
            console.error(e);
            setTimeout(async () => {
              setBusy(false)
            }, 1000);               
        }

    }
  };

  const onSetEmail = ( email ) => {

    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValidEmail = emailRegex.test(email);
    setEmailError( !isValidEmail );
    console.log("Email validation", isValidEmail );
    setEmail( email );
  }

  const onSetPassword = ( password ) => {
    var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}/;
    var isValidPassword = passwordRegex.test(password);
    setPassword( password );
    setPasswordError( !isValidPassword ); 
  }

  const handleClickShowPassword = () => {
    setShowPassword( !showpassword );
  }

  const handleMouseDownPassword = () => {
    setShowPassword( !showpassword );
  }



  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Facilitators Sign-in
          </Typography>
          {
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                disabled={busy}
                onChange={(e) => onSetEmail(e.target.value)}
                error={emailerror}
                autoFocus
              />
    
              <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth required>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  name="password"
                  type={showpassword ? 'text' : 'password'}
                  required
                  autoComplete="current-password"
                  value={password}
                  disabled={busy}
                  onChange={(e) => onSetPassword( e.target.value )}
                  error={passworderror}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showpassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

            

              {
                error &&
                <Alert severity="error">{error}</Alert>

              }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={busy}
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item sm>
                  <Link href="/facilitators/reset" 
                        disabled={busy}
                        variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          }
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}