import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignIn from './components/routing/SignIn';
import Reset from './components/routing/Reset';
import Code from './components/routing/Code';
import Password from './components/routing/Password';
import Manage from './components/routing/Manage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import './index.scss';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#fff',
      contrastText: "#fff", //button text white instead of black
    },
    tertiary:{
      main: '#fff',
      contrastText: "#fff", //button text white instead of black
    }

  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
          <BrowserRouter>
              <Routes>
                  <Route path="/facilitators/*" element={<SignIn />} />
                  <Route path="/facilitators/reset" element={<Reset />} />
                  <Route path="/facilitators/code" element={<Code />} />
                  <Route path="/facilitators/password" element={<Password />} />
                  <Route path="/facilitators/manage" element={<Manage />} />
              </Routes>
          </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
