import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

export default function QuestionnaireDialog( props ) {

  const {open, setClose, questionnaire} = props;  
  const handleClose = () => {
    setClose(false);
  };

  const jsonUnescape = (str) =>{
    return  str?.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\").replace(/\\n/g, "<br>");
  }



  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={true}>
        <DialogTitle>Questionnaire</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>This questionnaire has been completed by or on behalf of the client.</b> 
          </DialogContentText>
          <DialogContentText>
            {
                questionnaire?.detail.map( (q, i) => (
                    <Box sx={{mt:1}}>
                        <Typography variant="body1">
                            <span className="question" dangerouslySetInnerHTML={{__html: jsonUnescape(q.q)}}></span>
                            {
                                typeof q?.a === 'string' &&
                                <span dangerouslySetInnerHTML={{__html: jsonUnescape(q.a)}}></span>
                            }
                            {
                                Array.isArray( q?.a ) &&
                                <ul>
                                    {
                                        q.a.map( (a, i) => (
                                            <li key={`answer-map-${i}`}> <span dangerouslySetInnerHTML={{__html: jsonUnescape(a)}}></span></li>
                                        ))
                                    }
                                </ul>
                            }                            
                        </Typography>   
                    </Box>
                ))
            }
        
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}