import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { post } from '../libraries/network';
import { Alert } from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://reservie.net/">
        reservie Ltd
      </Link>{' '}
            {new Date('2015-01-01 00:00:00').getFullYear()} - {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Code() {
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSubmit = async(event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      code: data.get('code'),
    });

    const s = `code=${encodeURIComponent(data.get('code'))}`;

    try{

      setBusy(true)
      const d = await post( `/facilitators/ajax-check-code`, s);

      if(d.status === 'OK')
      {
        setTimeout(async () => {

          const {
            origin
          } = window.location;
          window.location.replace(`${origin}/facilitators/password`);
          setBusy(false)
  
        }, 500);
      }
      else
      {
        console.error(d);
        setTimeout(async () => {
          setBusy(false)
        }, 1000);           
        setError('Invalid code entered. Please check the details and try again.');
      }


    }
    catch(e)
    {
        if(window?.static_override)
        {
            setTimeout(async () => {

            const {
              origin
            } = window.location;
            window.location.replace(`${origin}/facilitators/password`);
            setBusy(false)

          }, 2000);

        }
        else
        {
          console.error(e);
          setTimeout(async () => {
            setBusy(false)
          }, 1000);             
            setError("Sorry, we have detected a network error. Please try again later.");
        }

    }    
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Facilitators forgotten password reset
          </Typography>
          <p>We have sent you a code to verify your email address. Please check your email account and enter the code in the box below. If you have not received the code, please check your junk/spam folder.</p>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="text"
              label="Verfication Code"
              name="code"
              autoComplete="off"
              autoFocus
              placeholder='XXXXX'
              disabled={busy}
            />
            {
              !!error &&
              <Box sx={{pt: 2, pb: 2}}>
                <Alert severity="error">{error}</Alert>
              </Box>
            }

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SendIcon />}
              disabled={busy}
            >
              Continue
            </Button>
            <Grid container>
              <Grid item sm>
                <Link href={`/facilitators/code-resend`} variant="body2">
                  Not received your verification code? Try again!
                </Link>
              </Grid>
            </Grid>              
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}