import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Grid, TextField, createFilterOptions, Container, Typography, MenuItem, Select, InputLabel, FormControl, FormControlLabel, RadioGroup, FormLabel, Radio, Alert, Link } from '@mui/material';
import { Box } from '@mui/system';
import { MuiTelInput } from 'mui-tel-input'

const filter = createFilterOptions();

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
  }
}


function PaymentSelect( props ) {
  const {busy, mode, onChange, cash_allowed, credits_allowed} = props;
  return (
    <FormControl disabled={busy}>
      <FormLabel id="payment-select-group-label">Payment type</FormLabel>
      <RadioGroup
        aria-labelledby="payment-select-group-label"
        defaultValue="cash"
        value={mode}
        name="radio-buttons-group"
        onChange={onChange}
      >
        <FormControlLabel disabled={!cash_allowed} value="cash" control={<Radio />} label="Cash" />
        <FormControlLabel disabled={!credits_allowed}  value="credit" control={<Radio />} label="Credit" />
      </RadioGroup>
    </FormControl>
  );
}

export default function RegisterClientDialog( props ) {

  const {open, clients, setClose, onBlock, settings} = props;  

  const [clientList, setClientList ] = React.useState([]);
  const [firstname, setFirstName] = React.useState('');
  const [lastname, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [emailerror, setEmailError] = React.useState(false);
  const [option, setOption] = React.useState( settings?.options.length > 0 && settings?.options[0] || null);
  const [price, setPrice] = React.useState(0);
  const [credit, setCredit] = React.useState(0);
  const [mode, setMode] = React.useState('cash');
  const  country = getNavigatorLanguage().split("-");
  const [busy, setBusy] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [lock, setLock] = React.useState(true);
  const [contactlock, setContactLock] = React.useState(false);

  console.log("country names", getNavigatorLanguage());

  const handleClose = () => {
    setClose(false);
  };

  React.useEffect(() => {

    console.log("options...", option);
    setLock(true);
    if(!!option)
    {
      setPrice(option?.price);
      setCredit(option?.credits)
    }

    if(settings)
    {
      console.log("Checking mode", settings?.cash, settings?.credits);
      if(settings?.cash === true)
      {
        setMode('cash');
      }
      else if(settings?.credits === true)
      {
        setMode('credit');
      }
    }

    if((typeof firstname === 'string') && (typeof lastname === 'string') && (typeof email === 'string') && (typeof mobile === 'string'))
    {
      if(firstname.length > 0 && lastname.length > 0 && email.length > 0 && mobile.length > 0)
      {
        setLock(false);
      }
    }



  }, [option, settings, firstname, lastname, email, mobile])


  const onSetEmail = ( email ) => {

    var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    var emailRegexMatch = emailRegex.exec(email);
    if (emailRegexMatch) {
      setEmailError(false);
    } else {
      setEmailError(true)
    }

    setEmail( email );
  }



  const onSetPrice = ( e ) => {
    if(!isNaN(e))
    {
      const v = parseFloat(e);
      if(v >= 0)
      {
        setPrice(v);
      }
    }
  }


  const onSetCredit = ( e ) => {
    if(!isNaN(e))
    {
      const v = parseInt(e);
      if(v >= 0)
      {
        setCredit(v);
      }
    }
  }

  const onRegister = (e) => {
    console.log("submitting...");
    e.preventDefault();
    setBusy(true);
    onBlock(true)
    let chain = `?firstname=${encodeURIComponent(firstname)}`;
     chain   += `&lastname=${encodeURIComponent(lastname)}`;
     chain   += `&email=${encodeURIComponent(email)}`;
     chain   += `&mobile=${encodeURIComponent(mobile)}`;
     chain   += `&mode=${encodeURIComponent(mode)}`;
     chain   += `&price=${encodeURIComponent(price)}`;
     chain   += `&credit=${encodeURIComponent(credit)}`;
     chain   += `&option=${encodeURIComponent(option.id)}`;

    fetch(`/facilitators/ajax-register-customer`,{
      headers : { 
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Requested-With": 'XMLHttpRequest',
        "X_REQUESTED_WITH": 'xmlhttprequest',
        'Accept': 'application/json',
        'credentials': "include"
       },
       body: window?.configuration?.tokenname + '=' + window?.configuration.tokenvalue + chain,
    })
    .then(function(response){
        console.log(response)
        return response.json();
    })
    .then(function(json) {
        console.log(json);
        setBusy(false);
        setClose();
        onBlock(false)
    }).catch((err) => {
      console.log(err);
      setError("Failed to register client")
      setBusy(false);
      setClose();
      onBlock(false)

    });   


  }

  React.useEffect(() => {
    const x = clients?.map((c) => {
      let e = c.email.replace(
        /(..)(.{1,2})(?=.*@)/g,
        (_, a, b) => a + '*'.repeat(b.length)
      );
      return {
        label: `${c.firstname} ${c.lastname} (${e})`,
        firstname: c.firstname,
        lastname: c.lastname,
        email: c.email,
        mobile: c.mobile,
        id: c.id
      }
    }) || [];
    setClientList(x);

  }, [clients]);


  console.log("busy or lock.", busy, lock, contactlock);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={true}>
      <form onSubmit={onRegister}>
        <DialogTitle>Client registration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>Please complete the following form to register a client.</b> 
          </DialogContentText>
          <Box sx={{mt:2}}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        value={firstname}
                        fullWidth
                        disabled={busy}
                        required
                        onChange={(event, newValue) => {
                          setContactLock(false);
                          console.log("on change", newValue);
                          if (typeof newValue === 'string') {
                            console.log("on change", newValue);

                            const c = clientList.find( c => c.firstname === newValue);
                            if(c)
                            {
                              setFirstName(c.firstname);
                             if(lastname === c.lastname && lastname !== '')
                              {
                                setEmail(c.email);
                                setMobile(c.mobile);
                                setContactLock(true)
                              } 
                            }
                            else
                            {
                              console.log("New STRING value", newValue);
                              setFirstName( newValue );
                            }

                            
                            
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setFirstName( newValue.inputValue );

                          } else {
                            console.log("Selecting...", newValue);
                            setFirstName( '' );
                            setEmail( '' );
                            setMobile('');

                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          // Suggest the creation of a new value
                          const s = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);                
                          console.log("inputValue",s);
                          
                          const isExisting = options.some((option) => inputValue === option.firstname && lastname !== '' && option.lastname === lastname );
                          const startsWith = options.some((option) => {

                            console.log("filter check ", option.firstname.startsWith(inputValue), option.lastname, lastname);
                            return option.firstname.startsWith(inputValue) && (option.lastname.startsWith(lastname) || option.lastname === '')
                          }); 
                      
                          
                          
                          console.log("startsWith", startsWith, isExisting);
                          if (inputValue !== '' && !isExisting && !startsWith) {

                            const s = inputValue.charAt(0).toUpperCase() + inputValue.toLocaleLowerCase().slice(1);                
                            
                            console.log("Adding....", s);
                            filtered.push({
                              id: 'new',
                              type: 'new',
                              firstname: `${s}`,
                              lastname: ""
                            });

                            clientList?.filter(c => c.id !== 'new' ).push({
                              id: 'new',
                              type: 'new',
                              firstname: `${s}`,
                              lastname: "",
                            });
                            
                            //setFound(inputValue);

                          }
                          else if (inputValue !== '' && !isExisting) {
                          
                            const s = inputValue.charAt(0).toUpperCase() + inputValue.toLocaleLowerCase().slice(1);                

                            console.log("Adding....", s);
                            
                            filtered.push({
                              id: 'new',
                              type: 'new',
                              firstname: `${s}`,
                              lastname: ""
                            });

                            clientList?.filter(c => c.id !== 'new' ).push({
                              id: 'new',
                              type: 'new',
                              firstname: `${s}`,
                              lastname: ""
                            });
                            

                          }

                          console.log("Final list.... ", filtered);
                          return filtered.filter( f => ((f.lastname === lastname || lastname === '')  && f.firstname.startsWith(inputValue)));
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="client-select-firstname"
                        options={clientList}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          console.log("get option label", option);
                          
                          if (typeof option === 'string') {
                            const s = option.charAt(0).toUpperCase() + option.toLowerCase().slice(1)
                            console.log("is string", s);
                            return s;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            console.log("is input", option);
                            return option.firstname;
                          }
                          // Regular option
                          const c = clientList.find( c => c.firstname === option.firstname);
                          console.log("is regular", option, clientList, c);
                          if(!!c)
                          {
                            return c.firstname;
                          }
                          else
                          {
                            return option.firstname;
                          }


                        }}
                        renderOption={(props, option) => <li {...props}>{option.firstname}</li>}
                        freeSolo
                        autoSelect
                        renderInput={(params) => (
                          <TextField {...params} fullWidth required autoFocus label="First name" />
                        )}
                      />   
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        value={lastname}
                        fullWidth
                        disabled={busy}
                        required
                        onChange={(event, newValue) => {
                          console.log("on change", newValue);
                          if (typeof newValue === 'string') {
                            console.log("on change", newValue);
                            setContactLock(false)
                            const c = clientList.find( c => c.lastname === newValue);
                            if(c)
                            {
                              setLastName(c.lastname);
                              if(firstname === c.firstname && firstname !== '')
                              {
                                setContactLock(true);
                                setEmail(c.email);
                                setMobile(c.mobile);

                              } 
                            }
                            else
                            {
                              console.log("New STRING value", newValue);
                              setLastName( newValue );
                              setEmail( '' );
                              setMobile( '' );
  
                            }

                            
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            console.log("name", newValue);
                            setLastName( newValue.inputValue );
                            setEmail( '' );
                            setMobile( '' );

                          } else {
                            console.log("Selecting...", newValue);
                            setLastName( '' );
                            setEmail( '' );
                            setMobile( '' );


                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          // Suggest the creation of a new value
                          const s = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);                
                          console.log("inputValue",s);
                          
                          const isExisting = options.some((option) => inputValue === option.lastname && firstname !== '' && option.firstname === firstname );
                          const startsWith = options.some((option) => {

                            console.log("filter check ", option.lastname.startsWith(inputValue), option.firstname, firstname);
                            return option.lastname.startsWith(inputValue) && (option.firstname.startsWith(firstname) || option.firstname === '')
                          }); 
                      
                          
                          
                          console.log("startsWith", startsWith, isExisting);
                          if (inputValue !== '' && !isExisting && !startsWith) {

                            const s = inputValue.charAt(0).toUpperCase() + inputValue.toLocaleLowerCase().slice(1);                
                            
                            console.log("Adding....", s);
                            filtered.push({
                              id: 'new',
                              type: 'new',
                              firstname: "",
                              lastname: `${s}`
                            });

                            clientList?.filter(c => c.id !== 'new' ).push({
                              id: 'new',
                              type: 'new',
                              firstname: "",
                              lastname: `${s}`
                            });
                            
                            //setFound(inputValue);

                          }
                          else if (inputValue !== '' && !isExisting) {
                          
                            const s = inputValue.charAt(0).toUpperCase() + inputValue.toLocaleLowerCase().slice(1);                

                            console.log("Adding....", s);
                            
                            filtered.push({
                              id: 'new',
                              type: 'new',
                              firstname: "",
                              lastname: `${s}`
                            });

                            clientList?.filter(c => c.id !== 'new' ).push({
                              id: 'new',
                              type: 'new',
                              firstname: "",
                              lastname: `${s}`
                            });
                            

                          }

                          console.log("Final list.... ", filtered);
                          return filtered.filter( f => f.lastname.startsWith(inputValue) && (f.firstname === firstname || firstname === ''));
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="client-select-firstname"
                        options={clientList}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          console.log("get option label", option);
                          
                          if (typeof option === 'string') {
                            const s = option.charAt(0).toUpperCase() + option.toLowerCase().slice(1)
                            console.log("is string", s);
                            return s;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            console.log("is input", option);
                            return option.lastname;
                          }
                          // Regular option
                          const c = clientList.find( c => c.lastname === option.lastname);
                          console.log("is regular", option, clientList, c);
                          if(!!c)
                          {
                            return c.lastname;
                          }
                          else
                          {
                            return option.lastname;
                          }


                        }}
                        renderOption={(props, option) => <li {...props}>{option.lastname}</li>}
                        freeSolo
                        autoSelect
                        renderInput={(params) => (
                          <TextField {...params} fullWidth required label="Last name" />
                        )}
                      />   
                    </Grid>  

                    <Grid item xs={12} md={6}>
                        <TextField 
                          type="email"
                          fullWidth
                          disabled={busy}
                          required
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => onSetEmail(e.target.value)}
                          label="email"
                          error={emailerror}
                          helperText={emailerror?"Invalid email address":"Enter email address"}
                        />
                    </Grid>  
                    <Grid item xs={12} md={6}>
                        <MuiTelInput 
                          value={mobile.replace(/^0/,'')} 
                          disabled={busy}
                          required
                          helperText={"Enter mobile number"}
                          onChange={(e) =>{
                            console.log(e);
                            let regex =/^\+[0-9]{0,3}\s[1-9]$|^\+[0-9]{0,3}\s[1-9][0-9\s]{1,11}$|^\+[0-9]{0,3}\s$|^\+[0-9]{0,3}$/;
                            let v = e.match(regex);
                            console.log("is valid", v);
                            if(v)
                            {
                              setMobile(e);
                            }
                          } }
                          defaultCountry={country[1]}
                          fullWidth />
                    </Grid>  
   

                    <Grid item xs={12}>
                        <b>Booking information</b> 
                    </Grid>       
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="pricing-option-label">Price option</InputLabel>
                        <Select
                          labelId="pricing-option-label"
                          id="pricing-option"
                          value={option?.id}
                          label="Pricing option"
                          required
                          disabled={busy}
                          onChange={(e) => setOption(e.target.value)}
                        >
                          {
                              window?.static_class_setting?.options && 
                              window?.static_class_setting?.options.length > 0 && 
                              window?.static_class_setting?.options?.map( o => (
                                <MenuItem key={'mi-' + o.id} value={o.id}>{o.name}</MenuItem>
                              ))
                          }
                        </Select>
                      </FormControl>                      
                    </Grid>  
                    <Grid item xs={12}>
                       <PaymentSelect  mode={mode} busy={busy} cash_allowed={settings?.cash} credits_allowed={settings?.credits} onChange={(e) => setMode(e.target.value)}/>          
                    </Grid>    
                    {
                      mode === 'cash' &&
                      <Grid item xs={12}>
                          <TextField
                              id="price-number"
                              label="Price"
                              type="number"
                              disabled={busy}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              required
                              inputProps={{step: "0.01", min: 0 }}
                              value={price}
                              onChange={(e) => onSetPrice(e.target.value)}
                            /> 
                      </Grid>
                    }              
                    {
                      mode === 'credit' &&
                      <Grid item xs={12}>
                          <TextField
                              id="credit-number"
                              label="Pass credits"
                              type="number"
                              disabled={busy}
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{step: "1", min: 0}}
                              value={credit}
                              onChange={(e) => onSetCredit(e.target.value)}

                            /> 
                      </Grid>
                    }
                    <Grid item xs={12}>
                      {error && <Alert severity='error'>{error}</Alert>}
                    </Grid>

                    {
                      !busy &&
                      <Grid item xs={12}>
                          <Button type="submit" variant="outlined" fullWidth disabled={lock || busy} sx={{mt:2}}>Register client</Button>
                      </Grid>
                    }
                    {
                      busy &&
                      <Grid item xs={12}>
                          <Button type="submit" variant="outlined" fullWidth disabled={true} sx={{mt:2}}>Register client...</Button>
                      </Grid>
                    }
                   
            </Grid>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        </form>
      </Dialog>

    </div>
  );
}