
export const get = async(url) => {

    return new Promise(function(resolve, reject) {
        fetch(`${url}`,{
            method: "GET",
            headers : { 
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Accept': 'application/json',
                'credentials': "include",
             },
          })
          .then(function(response){
              console.log(response)
              return response.json();
          })
          .then(function(json) {
                console.log(json);
                resolve(json);
            }).catch((err) => {
                reject(err);
          }); 
    })
}


export const post = async(url, data) => {

    // escape the object for potential issues

    let u = window.configuration.k + '=' + window.configuration.v;

    if(!!data)
    {
        u = window.configuration.k + '=' + window.configuration.v + '&' + data;
    }

    return new Promise(function(resolve, reject) {
        fetch(`${url}`,{
            method: "POST",
            headers : { 
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest',
                'Accept': 'application/json',
                'credentials': "include",
             },
             body: u
          })
          .then(function(response){
              console.log(response)
              return response.json();
          })
          .then(function(json) {
              console.log(json);
              resolve(json)
    
            }).catch((err) => {
                console.log(err);
                reject(err);
          }); 
    });   
}

